.riskscore-container {
  margin-top: 20px;
  display: flex;
  width: 1218px;
  justify-content: space-between;
}
.riskscore-container .left {
  width: 910px;
  height: 742px;
  background: #FFFFFF;
  border: 0.5px solid #E8E8ED;
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  padding: 9px 21px 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.riskscore-loadicon {
  position: absolute;
  top: 70px;
  right: 25px;
}
.riskscore-container .title-wrap {
  padding-bottom: 8px;
  border-bottom: 1px solid #EBEBF0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.riskscore-container .left .title {
  font-weight: 700;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  letter-spacing: 0.12px;
  color: #425466;
  margin: 0;
}
.riskscore-container .select-cpt {
  width: 250px;
  height: 40px;
  display: flex;
  align-items: center;
}

.select-cpt select {
  font-size: 14px;
  box-shadow: none;
}
.select-cpt p {
  width: 60px;
  margin: 0 8px 0 0;
  color: #646F79;
  font-size: 14px;
}

.riskscore-container .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* .left nav.nav-list {
} */
.left nav.nav-list li {
  list-style: none;
  display: inline-block;
  width: 160px;
  text-align: center;
  height: 47px;
  line-height: 47px;
  cursor: pointer;
  transition: .5s ease;
  border-bottom: 2px solid #fff;
  font-size: 14px;
}
.left nav.nav-list li:hover, .left nav.nav-list li.active {
  border-color: #4C6FFF;
}
