.risk-assessment-container {
  background: #FFFFFF;
  border: 0.5px solid #E8E8ED;
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  height: 395px;
  padding: 29px 22px;
}
.risk-assessment-container .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.12px;
  color: #425466;
  margin-bottom: 34px;
}
.risk-assessment-container ul {
  list-style: none;
  padding: 0;
  height: 290px;
  margin: 0;
  overflow-y: scroll;
}
.risk-assessment-container li {
  line-height: 0;
}
.risk-assessment-container li + li {
  margin-top: 20px;
}

.risk-assessment-container li img {
  width: 13px;
  margin-top: -2px;
}
.risk-assessment-container li span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.12px;
  color: #0A0A0A;
  padding-left: 5.8px;
}
.risk-assessment-container li p.detail {
  margin-left: 18px;
  padding: 15px 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.12px;
  color: #425466;
  background: #F9F9FA;
  border-radius: 4px;
  margin-top: 9px;
}
.risk-assessment-container li p.describe {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  letter-spacing: 0.12px;
  color: #606E7B;
  margin-bottom: 9px;
}